@import "node_modules/@digitallyhappy/backstrap/src/scss/_backstrap_colors";

@import url('https://fonts.googleapis.com/css?family=Overpass');

$font-family-base: 'Overpass', sans-serif;

$primary: #1dd3b1; // $black !default; // <--- For eg. This will make all buttons and texts black instead of purple
$secondary: #727cf5; // $gray-300 !default;
$success: $green !default;
$info: $blue !default;
$warning: #fbbc06; // $yellow !default;
$danger: #ff3366; // $red !default;
$light: $gray-200 !default;
$dark: $black !default;

$hover-color: rgba(105, 171, 239, 0.12);
$border-color: rgba(0, 40, 100, 0.12);
$muted-bg-color: rgba(0, 0, 0, 0.02);

$theme-colors: (
    ) !default;
$theme-colors: map-merge(("primary": $primary,
            "default": $secondary,
            "secondary": $secondary,
            "success": $success,
            "info": $info,
            "notice": $info,
            "warning": $warning,
            "danger": $danger,
            "error": $danger,
            "light": $light,
            "dark": $dark ),
        $theme-colors
);

// Booststrap functions and variables
@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';

// SCSS compass funtions
@import '~compass-mixins/lib/compass';
//@import '~compass-mixins/lib/animate';
// Bootstrap main SCSS
@import '~bootstrap/scss/bootstrap.scss';

@import 'sass/mixins/blockquote';
@import 'sass/mixins/cards';
@import 'sass/mixins/width';

// Template variables
@import 'sass/variables';
@import 'sass/background';
@import 'sass/reset';
@import 'sass/functions';
@import 'sass/fonts';
// @import url('https://fonts.googleapis.com/css?family=Overpass:300,400,600,700,800');
@import 'sass/footer';
@import 'sass/misc';
@import 'sass/utilities';
@import 'sass/demo';
@import 'sass/typography';
@import 'sass/spinner';
@import 'sass/vertical-wrapper';

@import 'sass/components/cards';

@import 'sass/custom';
@import 'sass/customcard';
