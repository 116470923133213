// Write your custom css/scss here


.btn-success {
    color: white;

    &:hover {
        background-color: theme-color(success-hover);
        border-color: theme-color(success-hover);
    }
}

.btn-outline-success:hover {
    color: white;
    background-color: theme-color(success);
    border-color: theme-color(success);
}

.datepicker.datepicker-dropdown .datepicker-days table.table-condensed tbody td.day.today:before {
    background: theme-color(success);
}

.form-check-input {
    vertical-align: middle;
}

.smalltopmargin {
    margin-top: 10px;
}

.card-body img.profileimage {
    padding: 5px
}

.black {
    color: black;
}

.removefieldbutton {
    float: right;
    cursor: pointer;
}

.removefieldbutton svg {
    stroke: red;
}

.card-links .feather {
    float: left;
    margin-top: 5px;
    width: 32px;
    height: 32px;
}

.card-links p,
.card-links h6 {
    margin-left: 65px;
}

.card-links h6 {
    color: black;
}

.bigger-footer-text {
    font-size: 120%
}

.app-header a.navbar-brand img {
    max-width: 100%;
    width: 100px;
}

.nav-item.nav-profile {
    @media(min-width:991.98px) {
        padding-right: 24px;
    }
}

.nav-item.nav-profile .nav-link img {
    object-fit: cover;
    height: 35px;
    border-radius: 50%;
    width: 35px;
}

#notificationBell {
    margin-top: 9px;
    display: inline-flex;
}

#notificationIndicator {
    .HW_badge {
        background: theme-color(success);
    }
}

ul.profile-nav {
    list-style: none;
}

ul.profile-nav li a {
    color: darkslategray;
}

.navbar {
    padding: 0px !important;
}

/* font size hack */
.container-fluid h2 span.text-capitalize {
    font-size: 1.4rem;
}

#datatable_info_stack {
    display: none !important;
}

#datatable_info_stack.datatable_info_stack-shown {
    display: inline-flex !important;
}

.dropdown-body .profile-nav .nav-item svg {
    width: 16px;
    height: 16px;
    margin-right: 10px;
}

.dropdown-body .profile-nav .nav-item {
    text-align: left;
}

.alert.error-box b a,
.noty_type__error .noty_body a {
    color: white;
    text-decoration: underline white dotted;
}

// hide checkbox in show modal boxes
.modal .modal-body .table .crud_bulk_actions_checkbox {
    display: none;
}

// show first column as bold text when it can be clicked to show the record
#crudTable.has-hidden-columns td.dtr-control span {
    font-weight: bold;
}

// disable responsive icon in datatable + normal cursor
#crudTable.has-hidden-columns td.dtr-control:before {
    display: none !important;
}
#crudTable.has-hidden-columns td.dtr-control {
    cursor: initial !important;
}

// hide print icon
section.container a.btn>i.la-print {
    display: none;
}

#crudTable_filter {
    float: right;
}

#uploadedProfileLogo,
#uploadedProfile {
    background-color: lightgray;
}

.info-bar {
    top: 0;
    left: 0;
    width: 100%;
    background-color: #186AF6;
    color: white;
    height: 50px;
    text-align: center;
    line-height: 50px;
    z-index: 9999;
    transition: all 0.5s ease;
}

.info-bar a {
    color: white;
    font-weight: bold;
}

.info-bar-close-btn {
    position: absolute;
    top: 0px;
    right: 0px;
    cursor: pointer;
    padding: 0px 20px;
}

.info-bar-close-btn:hover {
    color: #f5f5f5;
}

.info-bar-hide {
    transform: translateY(-50px);
    position: absolute;
}

.app-body {
    overflow-x: unset;
}

.app-body .main {
    background: #F2F8FE;
}

.sidebar-show .main::before {
    background: transparent;
}
